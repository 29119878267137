<template>
    <div>
        <b-row align-v="end">
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">
                    {{ fullName }}
                </dmx-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                <b-button v-if="user.id != 0 && stateUser.userRole >= userRoles.DemexAdmin" variant="danger"
                          v-b-modal.delete-user-modal class="mr-1">
                    {{ $t('common_delete') }}
                </b-button>
                <b-button variant="success" @click="updateUser()">
                    {{ (user.id != 0) ? $t('common_save') : $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <div class="dmx-card mt-3">
            <b-row class="d-flex justify-content-between"
                   v-if="stateUser.userRole >= userRoles.DemexAdmin && stateUser.userRole >= user.userRole"
                   style="padding:10px 10px 0 10px;">
                <div>
                    <label class="mr-1 font-weight-bolder">Active</label>
                    <label class="switch">
                        <input type="checkbox" v-model="user.isActive" />
                        <span class="slider_check round"></span>
                    </label>
                </div>
                <div>
                    <p class="text-danger">{{ errorMessage }}</p>
                </div>
            </b-row>
            <b-row>
                <b-col v-if="stateUser.userRole >= userRoles.DemexAdmin && stateUser.userRole >= user.userRole" cols="12">
                    User role
                    <b-select v-model="user.userRole" :options="availableUserRoles"></b-select>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    {{ $t('common_firstname') }}<small class="text-danger">
                        <em>
                            *
                        </em>
                    </small>
                    <ValidationProvider name="Firstname" rules="required" v-slot="v">
                        <b-form-input spellcheck="false" type="text" autocomplete="off"
                                      v-model="user.firstName"></b-form-input>
                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    {{ $t('common_lastname') }}<small class="text-danger">
                        <em>
                            *
                        </em>
                    </small>
                    <ValidationProvider name="Lastname" rules="required" v-slot="v">
                        <b-form-input spellcheck="false" type="text" autocomplete="off"
                                      v-model="user.lastName"></b-form-input>
                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    {{ $t('common_phone') }}
                    <b-form-input v-model="user.phone"></b-form-input>
                </b-col>
                <b-col>
                    {{ $t('common_email') }}<small class="text-danger">
                        <em>
                            *
                        </em>
                    </small>
                    <ValidationProvider name="Email" rules="required|email" v-slot="v">
                        <b-form-input spellcheck="false" type="text" autocomplete="off" v-model="user.email"></b-form-input>
                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    {{ $t('common_password') }}<small class="text-danger">
                        <em>
                            *
                        </em>
                    </small>
                    <ValidationProvider name="Password" rules="required|password:@Confirm" v-slot="v">
                        <b-form-input spellcheck="false" type="text" autocomplete="off"
                                      v-model="user.password"></b-form-input>
                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    {{ $t('common_confirm_password') }}<small class="text-danger">
                        <em>
                            *
                        </em>
                    </small>
                    <ValidationProvider name="Confirm" rules="required" v-slot="v">
                        <b-form-input spellcheck="false" type="text" autocomplete="off"
                                      v-model="user.confirmPassword"></b-form-input>
                        <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <hr />
            Customer
            <b-select v-model="user.selectedCustomerId" :options="customerListFormatted"></b-select>
            <hr />
            <b-row class="mt-2 mb-1">
                <b-col cols="5">
                    Search and add a gate to user
                    <vue-bootstrap-typeahead v-if="user.id > 0" :data="freeGates" v-model="searchGates"
                                             :serializer="s => s.name" placeholder="Type a gate name ..." backgroundVariant="light"
                                             @hit="selectGate" />
                </b-col>
                <b-col cols="3">
                    Select initial gate role
                    <b-select v-model="selectedGateRole" :options="availableGateRoles"></b-select>
                </b-col>
                <b-col cols="3">
                    {{ $t('common_validto') }}
                    <b-form-datepicker v-model="validToDateTime"></b-form-datepicker>
                </b-col>
                <b-col cols="1" class="d-flex align-items-end justify-content-end">
                    <b-button variant="success btn-block" @click="connectGate" class="mt-4">Add</b-button>
                </b-col>
            </b-row>

            <div class="mt-2">
                Gates currently available to user {{ fullName }}
                <b-list-group>
                    <b-list-group-item v-for="(item, i) in user.gates" :key="item.name"
                                       class="d-flex align-items-center justify-content-between">
                        <b-link @click="editGate(item)">{{ item.name }}</b-link>
                        <span>
                            <span class="small"><b>{{ getUserGateRoleFormatted(item.userGateRole) }}</b></span>
                            <b-link v-if="hasGatePermission(item.id, permissions.ManageUserPermissions)" class="ml-3 small"
                                    @click="editGatePermissions(item)">
                                Edit permissions
                            </b-link>
                            <b-link v-if="hasGatePermission(item.id, permissions.ManageUserPermissions)" class="ml-3 small"
                                    @click="editGateNotifications(item)">
                                <b-icon icon="bell-fill"></b-icon>
                            </b-link>
                            <b-link v-if="hasGatePermission(item.id, permissions.ManageUsers)" class="ml-3"
                                    @click="removeGate(item.id, item.name)">
                                <b-icon icon="trash-fill"></b-icon>
                            </b-link>
                        </span>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
        <b-modal size="md" id="delete-user-modal" title="Delete user" @ok="deleteUser()">
            <p>
                You are about to delete this user (<b>{{ user.firstName}} {{ user.lastName }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>
            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
        <b-modal size="md" id="delete-customer-gate-modal" title="Remove gate connection" @ok="doRemoveGateConnection()">
            <p>
                You are about to remove connection of <b>{{ gateToDeleteName }}</b>.<br />
                <b>Confirm?</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_remove') }}
                </b-button>
            </template>
        </b-modal>

        <b-modal size="lg" id="permission-modal" ref="permission-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit gate permissions for user <b>
                        {{ user.firstName }}
                        {{ user.lastName }}
                    </b> on <b>{{ selectedGate.name }}</b>
                </h5>
            </template>
            <gate-user-permissions v-if="selectedGate" :gateUser="user" :gate="selectedGate" />
        </b-modal>
        <b-modal size="lg" id="notifications-modal" ref="notifications-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit gate notification settings for user <b>
                        {{ user.firstName }}
                        {{ user.lastName }}
                    </b>
                </h5>
                <h5>on <b>{{ selectedGate.name }}</b></h5>
            </template>
            <gate-user-notifications v-if="selectedGate" :gateUser="gateUser" :gate="selectedGate" />
        </b-modal>
    </div>
</template>
<style scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 24px;
    }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

    .slider_check {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 7px;
        bottom: 0;
        background-color: #cc0000;
        -webkit-transition: .4s;
        transition: .4s;
    }

        .slider_check:before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            top: 3px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

    input:checked + .slider_check {
        background-color: #009900;
    }

    input:focus + .slider_check {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider_check:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider_check.round {
        border-radius: 18px;
    }

        .slider_check.round::before {
            border-radius: 50%;
        }
</style>
<script>
    import { UserRoles, Permissions } from '@/variables/variables.js'
    import { mapState, mapActions } from 'vuex';
    import accountService from '@/services/accountService';
    import notificationService from '@/services/notificationService';
    import gateService from '@/services/gateService';
    import customerService from '@/services/customerService';
    import router from '@/router';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import gateUserNotifications from "@/components/gates/gateUsers/gate-user-notifications.vue"
    import gateUserPermissions from '@/components/gates/gateUsers/gate-user-permissions.vue';

    export default {
        name: 'editUser',
        props: { userId: '' },
        components: {
            'vue-bootstrap-typeahead': VueBootstrapTypeahead,
            'gate-user-permissions': gateUserPermissions,
            'gate-user-notifications': gateUserNotifications,
        },
        data: () => ({
            gateAutocompleteLoading: false,
            errorMessage: '',
            searchGates: '',
            isEditing: false,
            emailRules: [
                v => /.+@.+/.test(v) || 'E-post är inte giltig',
            ],
            user: {
                id: 0,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                password: '',
                confirmPassword: '',
                isActive: false,
                selectedCustomerId: 0,
            },
            availableUserRoles: [],
            availableGateRoles: [],
            showRemoveGateDialog: false,
            gateToDeleteName: '',
            freeGates: [],
            customerList: [],
            customerListFormatted: [],
            selectedGate: false,
            gateUser: {},
            selectedGateRole: 10,
            validToDateTime: null,
            userRoles: UserRoles,
            permissions: Permissions,
        }),
        watch: {
            customerList: {
                handler() {
                    this.customerListFormatted = [{ value: 0, text: 'Select customer' }];
                    for (let i in this.customerList) {
                        this.customerListFormatted.push({ value: this.customerList[i].id, text: this.customerList[i].name });
                    }
                },
            },
        },
        methods: {
            deleteUser() {
                accountService.deleteUser(this.user)
                    .then(data => {
                        this.makeToast(`User`, `User ${this.user.firstName} ${this.user.lastName} has been deleted.`);
                        router.go(-1)
                    });
            },
            editGate(e) {
                router.push({ name: 'editGate', params: { gateId: parseInt(e.id) } })
            },
            editGatePermissions(gate) {
                this.selectedGate = gate;
                this.$refs['permission-modal'].show();
            },
            editGateNotifications(gate) {
                this.selectedGate = gate;
                this.gateUser = { id: gate.userGateId };
                this.$refs['notifications-modal'].show();
            },
            removeGate(gateId, gateName) {
                this.gateIdToDelete = gateId;
                this.gateToDeleteName = gateName;
                this.$bvModal.show('delete-customer-gate-modal');
            },
            doRemoveGateConnection() {
                accountService.removeConnectGate({ gateId: this.gateIdToDelete, userId: parseInt(this.userId) })
                    .then((data) => {
                        this.getUserById();
                        this.getAllFreeGates();
                        this.searchGates = '';
                        this.selectedGate = false;
                        this.makeToast(`User gate connection`, `Gate connection has been successfully removed.`);
                    });
            },
            selectGate(gate) {
                this.selectedGate = gate;
            },
            connectGate() {
                accountService.connectGate({ gateId: this.selectedGate.id, userId: parseInt(this.userId), gateRole: this.selectedGateRole, validTo: this.validToDateTime })
                    .then((data) => {
                        this.getUserById();
                        this.getAllFreeGates();
                        this.searchGates = '';
                        this.selectedGate = false;
                        this.makeToast(`User gate connection`, `Gate has been successfully connected.`);
                    });
            },

            getAllFreeGates() {
                this.freeGates = [];
                this.gates = [];
                gateService.getFreeGates(this.userId).then((data) => {
                    this.freeGates = data;
                }).catch(function (error) {
                    alert(error);
                });
            },
            getAvailableUserRoles() {
                this.avaiableUserRoles = [];
                accountService.getAvailableUserRoles()
                    .then((data) => {
                        this.availableUserRoles = [];
                        for (let key in data) {
                            this.availableUserRoles.push({ value: data[key], text: key });
                        }
                    });
            },
            getUserGateRoleFormatted(userRole) {
                for (let i in this.availableGateRoles) {
                    if (this.availableGateRoles[i].value == userRole) return this.availableGateRoles[i].text;
                }
            },
            getAvailableGateRoles() {
                this.avaiableGateRoles = [];
                gateService.getAvailableGateRoles()
                    .then((data) => {
                        this.availableGateRoles = [];
                        for (let key in data) {
                            this.availableGateRoles.push({ value: data[key], text: this.$t('gateRole_' + key) });
                        }
                    });
            },
            getCustomers() {
                this.customerList = [];
                customerService.getCustomers()
                    .then((data) => {
                        this.customerList = data;
                    });
            },
            getUserById() {
                accountService.getById("GetUserById", this.userId)
                    .then((data) => {
                        this.user = data;
                    });
            },
            updateUser() {
                this.errorMessage = '';
                accountService.update(this.user).then(result => {
                    if (result) {
                        this.setUserInfo();
                        router.go(-1)
                    } else {
                        this.errorMessage = "Error: Password would not update";
                    }
                });
            },
            hasGatePermission(gateId, perm) {
                if (this.stateUser.userRole >= this.userRoles.DemexAdmin) return true;
                else {
                    for (let i in this.stateUser.gates) {
                        if (this.stateUser.gates[i].id == gateId) {
                            return this.stateUser.gates[i].userGatePermissions.includes(perm);
                        }
                    }
                    return false;
                }
            },
            ...mapActions({
                setUserInfo: 'login/SET_USER_INFO',
            }),
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
            fullName() {
                if (!this.user.firstName && !this.user.lastName) {
                    return `${this.$t('missing_firstName')} ${this.$t('missing_lastName')}`;
                }
                return `${this.user.firstName} ${this.user.lastName}`;
            },
            bcItems() {
                return [
                    {
                        text: 'users',
                        href: '/users',
                    },
                    {
                        text: this.fullName,
                        href: '/user/edituser/' + this.user.id,
                        active: true,
                    }
                ]
            },
        },
        mounted() {
            if (this.userId > 0) {
                this.getUserById();
            }
            else {
                this.isEditing = true;
            }
            this.getCustomers();
            this.getAvailableUserRoles();
            this.getAvailableGateRoles();
            this.getAllFreeGates();

            this.$root.$on('dmx::saved::gateUserPermissions', () => {
                this.$refs['permission-modal'].hide();
                this.makeToast('Gate user permissions', `User permissions has been saved`);
                this.getUserById();
            });
            this.$root.$on('dmx::saved::gateUserNotifications', () => {
                this.$refs['notifications-modal'].hide();
                this.makeToast('Gate user notifications', `User notification settings has been saved`);
            });
        },
        beforeDestroy() {
            this.$root.$off('dmx::saved::gateUserPermissions');
        }
    }
</script>